import React, {useEffect, useState} from 'react'
import Banner from "./Banner";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {TableBody, TableContainer} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Link} from "react-router-dom";
import {trainingService} from "../service/training.service";

const useStyles = makeStyles({
    card: {
        marginTop: '2.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '40px',
        boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        borderRadius: '6px',
        zIndex: 3,
        position: 'relative',
        background: '#FFFFFF',
        maxWidth: '1140px',
        display: 'flex',
        justifyContent: 'center'
    },
    content: {
        maxWidth: '1140px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    rowLink: {
        textDecoration: 'none'
    }
})

function TrainingsView() {
    const [trainings, setTrainings] = useState([]);
    useEffect(() => {
        trainingService.getAll()
            .then(r => {
                setTrainings(r);
            })
    })

    const classes = useStyles();
    const bannerTitle = "Szkolenia zamknięte";
    const bannerDescription = "Przeprowadzamy szkolenia dla firm (szkolenia zamknięte z dojazdem oraz online).";

    return (
        <Box>
            <Banner title={bannerTitle} description={bannerDescription}/>
            <Box className={classes.card}>
                <TableContainer className={classes.content}>
                    <Table>
                        <TableBody>
                            {trainings.map((training) => (
                                <TableRow hover key={training.title}>
                                    <TableCell className={classes.rowLink} component={Link} scope="row"
                                               to={`/szkolenia-zamkniete/${training.slug}`}>
                                        {training.title}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

export default TrainingsView