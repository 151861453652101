import React, {useState} from 'react'
import AppBar from "@material-ui/core/AppBar";
import {ButtonGroup, Toolbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import 'fontsource-roboto'
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import {Menu} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: 1100,
        position: 'sticky',
        boxShadow: 'none',
        flexFlow: 'row nowrap',
        backgroundColor: '#F4F5F7'
    },
    logoButton: {
        color: '#20acdb',
        textTransform: 'none',
        letterSpacing: 'unset',
        '&:hover': {
            background: 'transparent'
        },
        fontSize: '1.2rem'
    },
    navButton: {
        '&:hover': {
            color: '#20acdb'
        },
        textTransform: 'none'
    },
    toolBar: {
        maxWidth: '1140px',
        display: 'flex',
        flex: 1,
        flexWrap: 'nowrap',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        justifyContent: 'space-between'

    },
    logoBox: {
        flex: 1,
        color: '#20ACDB',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
}))

function Navigation() {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const menuItems = [
        {
            to: '/szkolenia-zamkniete',
            text: 'Szkolenia zamkniete'
        },
        {
            to: 'https://progressproject.pl/andrzej-sikorski-szkolenia',
            text: 'Szkolenia otwarte'
        },
        {
            to: '/o-nas',
            text: 'O nas'
        },
        {
            to: '/zgloszenia',
            text: 'Zgłoszenia'
        },
        {
            to: '/kontakt',
            text: 'Kontakt'
        }
    ]

    const menu = (
        menuItems.map(item => {
                if (item.to.startsWith('/')) {
                    return <Button disableElevation className={classes.navButton} component={Link}
                                   to={item.to}>{item.text}</Button>
                }
                return <Button disableElevation className={classes.navButton}
                               href={item.to}>{item.text}</Button>
            }
        )
    )

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    return (
        <AppBar className={classes.appBar} color="transparent">
            <Toolbar className={classes.toolBar}>
                <Box className={classes.logoBox}>
                    <Button className={classes.logoButton} component={Link} to='/'>ABC Sikorski</Button>
                </Box>
                <Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <Menu/>
                    </IconButton>
                    <Hidden mdUp>
                        <Drawer variant="temporary" anchor='right' open={mobileOpen} onClose={handleDrawerToggle}
                                ModalProps={{keepMounted: true}}>{menu}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown>
                        <ButtonGroup>
                            {menu}
                        </ButtonGroup>
                    </Hidden>
                </Box>
            </Toolbar>

        </AppBar>
    )
}

export default Navigation