import React from 'react'
import europegas from '../assets/clients/europegas.png'
import ikea from '../assets/clients/ikea.png'
import metracto from '../assets/clients/metraco.png'
import nestle from '../assets/clients/nestle.png'
import philips from '../assets/clients/philips.png'
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: '30vh',
        textAlign: 'center',
        marginTop: '20px',
        color: '#4fbbe0'
    },
    img: {
        maxWidth: '100%',
    }
})

const tileData = [
    {
        img: europegas,
        title: 'Europegas'
    },
    {
        img: metracto,
        title: 'Metracto'
    },
    {
        img: ikea,
        title: 'IKEA'
    },
    {
        img: nestle,
        title: 'nestle'
    },
    {
        img: philips,
        title: 'Philips'
    }
]

function ClientsShowcase() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Fade in={true} timeout={3000}>
            <Typography variant={"h3"}>
                Nasi klienci
            </Typography>
            </Fade>
            <Grid container direction={"row"}>
                {tileData.map((tile) => (
                    <Grid item xs key={tile.img}>
                        <Fade in={true} timeout={3000}>
                            <img src={tile.img} alt={tile.title} className={classes.img}/>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default ClientsShowcase