import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import CssBaseline from "@material-ui/core/CssBaseline";
import Navigation from "./component/Navigation";
import Home from "./component/Home";
import {createMuiTheme, responsiveFontSizes} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import TrainingsView from "./component/TrainingsView";
import About from "./component/About";
import Application from "./component/Application";
import Training from "./component/Training";
import Contact from "./component/Contact";
import Footer from "./component/Footer";
import Privacy from "./component/Privacy";
import Redirect from "react-router-dom/es/Redirect";

let theme = createMuiTheme()

theme = responsiveFontSizes(theme);

const App = () => {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Navigation/>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route path='/szkolenia-zamkniete/:slug' component={Training}/>
                    <Route path='/szkolenia-zamkniete' component={TrainingsView}/>
                    <Route path='/o-nas' component={About}/>
                    <Route path='/zgloszenia' component={Application}/>
                    <Route path='/kontakt' component={Contact}/>
                    <Route path='/polityka-prywatnosci' component={Privacy}/>
                    <Route component={Home}/>
                </Switch>
                <Footer/>
            </ThemeProvider>
        </Router>
    )
}

export default App