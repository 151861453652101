import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        backgroundColor: '#4fbbe0',
        height: '25vh',
        alignItems: 'center'
    },
    header: {
        maxWidth: '1140px',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: 'white',
        alignContent: 'center',
        textAlign: 'center'
    }
})

function Banner(props) {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Grid container direction="column" className={classes.header}>
                <Grid item>
                    <Typography variant={"h4"}>{props.title}
                    </Typography>
                    <Typography variant={"h5"}>{props.description}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Banner