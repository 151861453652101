import React from 'react'
import Banner from "./Banner";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Mail, Phone} from "@material-ui/icons";

const useStyles = makeStyles({
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        position: 'relative',
        maxWidth: '1140px',
        display: 'flex',
        alignContent: 'center',
        padding: '1.2rem'
    }
})

function Application() {
    const classes = useStyles();
    const title = "Zgłoszenie"

    return (
        <Box>
            <Banner title={title}/>
            <Box className={classes.content}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs>
                        <Typography variant="h5">Osoby zainteresowane uprzejmie prosimy o kontakt:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5"><Mail/><Link
                            href="mailto:a.sikorski@abcsikorski.pl"> a.sikorski@abcsikorski.pl</Link></Typography>
                        <Typography variant="h5"><Phone/> 602-232-510</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">udzielimy wszystkich informacji, w razie potrzeby złożymy
                            ofertę programową i finansową.</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Application