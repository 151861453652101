import React, {useEffect, useState} from 'react'
import Banner from "./Banner";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {trainingService} from "../service/training.service";
import {Link, useParams} from "react-router-dom"
import {Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        position: 'relative',
        maxWidth: '1140px',
        display: 'flex',
        alignContent: 'center',
        padding: '1.2rem'
    },
    applicationButton: {
        background: '#4fbbe0',
        color: '#fff',
        '&:hover': {
            background: '#20acdb'
        },
    },
    listStyle: {
        '& li': {
            fontWeight: 400,
            lineHeight: 2
        },
        '& > li, & > li > ul': {
            paddingBottom: '15px',
            fontWeight: 600
        }
    },
    header: {
        color: '#4fbbe0'
    }
})

function Training() {
    const [training, setTraining] = useState({})
    const [showDesc, setShowDesc] = useState(false)
    const [showLecturer, setShowLecturer] = useState(false)
    let {slug} = useParams();

    useEffect(() => {
            trainingService.getBySlug(slug)
                .then(r => {
                    let t = r
                    setTraining(t);
                    setShowDesc(t.description.length > 0);
                    setShowLecturer(t.lecturerDescription.length > 0)
                })
        }
    )

    const classes = useStyles();

    return (
        <Box>
            <Banner title={training.title}/>
            <Box className={classes.content}>
                <Grid container direction="column" spacing={3}>
                    {showDesc &&
                    <Grid item>
                        <Typography variant={"h5"} gutterBottom className={classes.header}>
                            Opis szkolenia
                        </Typography>
                        <Typography variant={"body1"} dangerouslySetInnerHTML={{__html: training.description}}/>
                    </Grid>
                    }
                    <Grid item>
                        <Typography variant={"h5"} gutterBottom className={classes.header}>
                            Program
                        </Typography>
                        <Typography variant={"body1"}>
                            <ol className={classes.listStyle} dangerouslySetInnerHTML={{__html: training.program}}/>
                        </Typography>
                    </Grid>
                    {showLecturer &&
                    <Grid item>
                        <Typography variant={"h5"} gutterBottom className={classes.header}>
                            Wykładowca
                        </Typography>
                        <Typography variant={"body1"} gutterBottom
                                    dangerouslySetInnerHTML={{__html: training.lecturerDescription}}/>
                    </Grid>
                    }
                    <Grid item>
                        <Button variant="contained" className={classes.applicationButton} component={Link}
                                to={`/zgloszenia`}>Zgłoszenie</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Training