import React from 'react'
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import bg from '../assets/bg-2.jpg'
import logo from '../assets/abc-sikorski-logo.png'
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        background: `linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)
        ),
        url(${bg})`,
        display: 'flex',
        backgroundSize: 'cover',
        backgroundColor: '#F4F5F7',
        backgroundPosition: 'center center',
        height: '70vh',
        alignItems: 'center'
    },
    header: {
        maxWidth: '1140px',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: 'white',
        padding: '1.2rem'
    },
    img: {
        maxWidth: '100%'
    }
})

function HomeBanner() {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Grid container direction="column" className={classes.header}>

                <Grid item>
                    <Fade in={true} timeout={2000}>
                        <img src={logo} alt="ABC Sikorski" className={classes.img}/>
                    </Fade>
                </Grid>
                <Grid item>
                    <Fade in={true} timeout={2000}>
                        <Typography variant={"h5"}>Jesteśmy firmą szkoleniową obecną na rynku od 1995r. <br/> Specjalizujemy się w branży TSL-
                            transport,
                            spedycja, logistyka i w tej dziedzinie jesteśmy zdecydowanym liderem.
                            Przeprowadzamy szkolenia <u>z dojazdem do klienta</u> oraz <u>online</u>.
                        </Typography>
                    </Fade>
                </Grid>
            </Grid>
        </Box>
    )

}

export default HomeBanner