import React from 'react'
import Banner from "./Banner";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {ButtonBase, Typography} from "@material-ui/core";
import book from '../assets/ksiega.png'

const useStyles = makeStyles({
    card: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '2.5rem',
        position: 'relative',
        maxWidth: '1140px',
        display: 'flex',
        padding: '1.2rem'
    },
    image: {
        width: 184,
        height: 258,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
})

function About() {
    const classes = useStyles();
    const title = "O nas"

    return (
        <Box>
            <Banner title={title}/>
            <Box className={classes.card}>
                <Grid container>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="h5">Jesteśmy firmą szkoleniową obecną na rynku od
                                    1995r. Specjalizujemy się w branży TSL- Transport, Spedycja, Logistyka i w tej
                                    dziedzinie jesteśmy zdecydowanym liderem.
                                    Założycielem firmy i czołowym wykładowcą jest mgr Andrzej Sikorski
                                </Typography>
                                <Typography gutterBottom>
                                    W 1980 roku ukończył Ekonomikę Transportu na Uniwersytecie Gdańskim. Wiedzę
                                    uzupełniał na kursach min. we Francuskim Instytucie Zarządzania i Zrzeszeniu
                                    Prawników Polskich. Autor wielu publikacji na tematy związane z handlem zagranicznym
                                    i transportem międzynarodowym.
                                </Typography>
                                <Typography gutterBottom>
                                    W wydawnictwie ODDK Gdańsk ukazała się jego książka „Transport i spedycja
                                    międzynarodowa w handlu zagranicznym”
                                </Typography>
                                <Typography gutterBottom>
                                    Od 30 lat praktycznie związany z logistyka międzynarodową. Karierę zawodową zaczynał
                                    w Zarządzie Portu Gdynia, potem: C. Hartwig Warszawa i zagraniczne firmy logistyczne
                                    LEP International i GeoLogistics Corp. Od 1996 roku prowadzi także działalność
                                    konsultanta i wykładowcy. Specjalizuje się w dochodzeniu roszczeń i reklamacjach z
                                    tytułu umowy sprzedaży międzynarodowej oraz spedycji i transportu.
                                </Typography>
                                <Typography gutterBottom>
                                    Szkolił takie firmy jak: Unilever, DSV, RABEN, GEFCO, ArcelorMittal, jest stałym
                                    wykładowcą dla Zrzeszenia Międzynarodowych Przewoźników Drogowych (ZMPD), były
                                    moderator na forum Giełdy Trans.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ButtonBase className={classes.image}>
                            <img className={classes.img} alt="" src={book}/>
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default About