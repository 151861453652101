import React from 'react'
import HomeBanner from './HomeBanner';
import ClientsShowcase from "./ClientsShowcase";

const Home = () => (
    <div>
        <HomeBanner/>
        <ClientsShowcase/>
    </div>

)

export default Home