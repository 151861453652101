import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    footer: {
        backgroundColor: "#F4F5F7",
        position: "fixed",
        bottom: "0",
        width: "100%",
        flexFlow: 'row nowrap',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
        zIndex: 1100
    },
    phantom: {
        display: "block",
        width: "100%",
        zIndex: 1100
    },
    privacyLink: {
        textTransform: 'none',
        '&:hover': {
            color: '#20acdb',
            background: 'transparent'
        },
    }
})



function Footer() {
    const classes = useStyles();
    const year = new Date().getFullYear()

    return (
        <Box>
            <Box className={classes.phantom} />
            <Box className={classes.footer}>
                <Box>©{year} ABC Sikorski</Box>
                <Box><Button className={classes.privacyLink} component={Link} to='/polityka-prywatnosci'>Polityka
                    prywatności</Button></Box>
            </Box>
        </Box>
    );
}

export default Footer