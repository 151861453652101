let trainings = [
    {
        slug: 'warunki-handlowe-incoterms',
        title: 'Warunki Handlowe INCOTERMS ®2020',
        description: 'Międzynarodowe Warunki Handlowe INCOTERMS®2020 nie są przepisami prawa ale zwyczajami\n' +
            'handlowymi a konkretnie uzansami, które poprzez precyzyjne  określenie zadań, kosztów i ryzyka\n' +
            'związanego z dostawą towarów pozwala na unikanie kosztownych nieporozumień między\n' +
            'przedsiębiorcami. \n' +
            '<br\\>Warunki Handlowe INCOTERMS® stosujemy do krajowych i międzynarodowych umów sprzedaży\n' +
            'towarów. Ich znajomość jest niezbędna dla handlowców, logistyków ale także dla spedytorów,\n' +
            'bankowców, ubezpieczycieli obsługujących międzynarodowe i krajowe transakcje handlowe.',
        program: '<li>Warunki dostaw INCOTERMS®2020 – pojęcia podstawowe:\n' +
            '                                    <ul>\n' +
            '                                        <li>czy jest obowiązek stosowania Warunków Handlowych INCOTERMS® ?</li>\n' +
            '                                        <li>co regulują a czego nie regulują INCOTERMS®2020</li>\n' +
            '                                        <li>co to jest „ryzyko” w dostawie</li>\n' +
            '                                        <li>co oznacza „termin dostawy”</li>\n' +
            '                                        <li>obowiązek dokonania odprawy celnej eksportowej/importowej</li>\n' +
            '                                        <li>możliwe modyfikacje formuł INCOTREMS®</li>\n' +
            '                                        <li>reklamacje z tytułu umowy sprzedaży a reklamacje z tytułu umowy o przewóz\n' +
            '                                            (uszkodzenie, ubytek, utrata towaru w transporcie, opóźnienie w\n' +
            '                                            dostawie)\n' +
            '                                        </li>\n' +
            '                                        <li>reklamacje z tytułu szkody w przewozie, kto jest uprawniony do ich składania\n' +
            '                                            i czy wynika to z INCOTERMS® czy z innych przepisów?\n' +
            '                                        </li>\n' +
            '                                        <li>czy można używać poprzednich wersji INCOTERMS® (np. INCOTERMS®2010 albo\n' +
            '                                            starszych) a jak z już zawartymi umowami?\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Podstawowe zmiany w wersji INCOTERMS®2020 w porównaniu z wersją INCOTERMS®2010 – co\n' +
            '                                    nowego a co pozostaje.\n' +
            '                                    <ul>\n' +
            '                                        <li>jak rozpoznać czy jest to wersja 2020 czy jeszcze 2010?</li>\n' +
            '                                        <li>rozszerzony wstęp i aktualizacja not wyjaśniających</li>\n' +
            '                                        <li>nowy układ artykułów (zmiany raczej techniczne, układ bardziej czytelny)\n' +
            '                                        </li>\n' +
            '                                        <li>zmiana inicjałów z DAT na DPU (delivery at place unloaded)</li>\n' +
            '                                        <li>zmiany w zakresie ubezpieczeń: CIP I CIF</li>\n' +
            '                                        <li>adnotacja “on board” na konosamencie przy FCA</li>\n' +
            '                                        <li>sytuacja użycia własnych środków przewozu przy FCA, DAP, DPU, DDP</li>\n' +
            '                                        <li>włączenie wymogów związanych z bezpieczeństwem w zakresie obowiązków\n' +
            '                                            dotyczących przewozu i kosztów (A4/B4 I A9/B9)\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Omówienie poszczególnych formuł – jak je stosować prawidłowo: zalecenia i\n' +
            '                                    przeciwskazania\n' +
            '                                    <ul>\n' +
            '                                        <li>Reguły dla wszystkich środków i gałęzi transportu\n' +
            '                                            <ul>\n' +
            '                                                <li>EXW EX Works (… named place)</li>\n' +
            '                                                <li>FCA Free Carrier (… named place)</li>\n' +
            '                                                <li>CPT Carriage PaidTo (… named place of destination)</li>\n' +
            '                                                <li>CIP Carriage Insurance Paid To (… named place of destination)</li>\n' +
            '                                                <li>DAP Delivered At Place (… named place of destination)</li>\n' +
            '                                                <li>DPU Delivery at Place Unloaded (… named place of destination)</li>\n' +
            '                                                <li>DDP Delivered Duty Paid (… named place of destination)</li>\n' +
            '                                            </ul>\n' +
            '                                        </li>\n' +
            '                                        <li>Ubezpieczenia CARGO w CIF i CIP – w pozostałych formułach „ubezpiecza ten co\n' +
            '                                            ma w tym interes” – co o ubezpieczeniu kupujący powinien obowiązkowo\n' +
            '                                            wiedzieć.\n' +
            '                                            <li>dlaczego wyłączne bazowanie na ubezpieczeniu przewoźnika (OCP) czy\n' +
            '                                                spedytora (OCS) jest niezalecane</li>\n' +
            '                                            <li>ograniczenia kwotowe przy odpowiedzialności przewoźnika w przypadku\n' +
            '                                                ubytku, uszkodzenia lub całkowitej utraty towaru\n' +
            '                                            </li>\n' +
            '\n' +
            '                                        </li>\n' +
            '                                        <li>Reguły dla transportu morskiego i wodnego śródlądowego\n' +
            '                                            <ul>\n' +
            '                                                <li>FAS Free Alonside Ship (…named port ofshipment)</li>\n' +
            '                                                <li>FOB Free On Board (… named port of shipment)</li>\n' +
            '                                                <li>CFR Cost and Freight (… named port of destination)</li>\n' +
            '                                                <li>CIF Cost Insurance Freight (…named port of destination)</li>\n' +
            '                                                <li>Inne?</li>\n' +
            '                                            </ul>\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Transport morski: odpowiedzialność przewoźnika morskiego, rola i znaczenie\n' +
            '                                    Konosamentu (B/L), co to jest morski list przewozowy i kiedy stosować zamiast B/L,\n' +
            '                                    demurrage, detention, …\n' +
            '                                </li>\n' +
            '                                <li>TOP10 czyli najczęściej popełniane błędy przy stosowaniu Warunków Handlowych\n' +
            '                                    INCOTERMS®\n' +
            '                                    <ul>\n' +
            '                                        <li>np. czemu zasadniczo nie zaleca się stosowania FAS, FOB, CFR i CIF – do\n' +
            '                                            sytuacji gdy przewóz towaru następuje w kontenerze, chociaż te formuły\n' +
            '                                            dotyczą transportu morskiego… a jeżeli nie te formuły to jakie?\n' +
            '                                        </li>\n' +
            '                                        <li>uzgodnienie warunków handlowych ale bez podania wersji INCOTERMS® a potem\n' +
            '                                            dopisywanie ich na dokumentach handlowych i transportowych\n' +
            '                                        </li>\n' +
            '                                        <li>dlaczego EXW należy stosować wyjątkowo rzadko,</li>\n' +
            '                                        <li>nadużywanie DDP</li>\n' +
            '                                        <li>powszechnie błędne interpretowanie terminu dostawy przy warunkach z grupy\n' +
            '                                            „C”\n' +
            '                                        </li>\n' +
            '                                        <li>inne, możliwe błędy przy stosowaniu aktualnej wersji INCOTERMS®2020</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Zalecenie\n' +
            '                                    <ul>\n' +
            '                                        <li>kiedy stosować daną formułę INCOTERMS®, przykłady praktyczne</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Dyskusja, pytania</li>',
        lecturerDescription: 'mgr Andrzej Sikorski - zawodowy trener, absolwent Uniwersytetu Gdańskiego a także\n' +
            'renomowanego Francuskiego Instytutu Zarządzania. <br/>Ma za sobą ponad 30 lat pracy w spedycji i\n' +
            'transporcie międzynarodowym. <br/>Od kilkunastu lat szkoli logistyków, spedytorów i przewoźników.\n' +
            'Jest autorem książki „Transport i spedycja międzynarodowa w handlu zagranicznym.”',
    },
    {
        slug: 'konwencja-cmr-i-prawo-przewozowe',
        title: 'Konwencja CMR i Prawo przewozowe.\n' +
            ' Umowa spedycji i umowa drogowego przewozu towarów – prawa i\n' +
            '\n' +
            'obowiązki stron, reklamacje.',
        description: 'Celem szkolenia jest usystematyzowanie wiedzy oraz zapoznanie uczestników z niuansami\n' +
            '                            prawnymi dotyczącymi transportu i spedycji tak, by zminimalizować ryzyko błędu przy\n' +
            '                            zawieraniu umowy, przygotowywaniu przewozu lub składaniu reklamacji.\n' +
            '                            <br/>\n' +
            '                            <br/>\n' +
            '                            Korzyści dla uczestników:\n' +
            '                            <ul>\n' +
            '                                <li>umiejętność rozróżniania umowę spedycji od umowy przewozu, będę również\n' +
            '                                    wiedzieli czego mają prawo wymagać od spedytora, a czego od przewoźnika,\n' +
            '                                </li>\n' +
            '                                <li>umiejętność prawidłowego zawierania umowy ( w zależności od potrzeby) przewozu\n' +
            '                                    lub spedycji\n' +
            '                                </li>\n' +
            '                                <li>znajomość praw i obowiązków wynikających z umów transportowych</li>\n' +
            '                                <li>uczestnicy będą potrafili prawidłowo złożyć reklamację z tytułu ubytku, uszkodzenia,\n' +
            '                                    utraty towaru oraz w przypadku opóźnienia w dostawie\n' +
            '                                </li>\n' +
            '                                <li>a spedytorzy i przewoźnicy nauczą się jak prawidłowo postępować w przypadku\n' +
            '                                    nieuzasadnionych reklamacji\n' +
            '                                </li>\n' +
            '                            </ul>',
        program: '<li>Umowa spedycji i odpowiedzialność spedytora\n' +
            '                                    <ul>\n' +
            '                                        <li>czym się różni umowa spedycji od umowy przewozu</li>\n' +
            '                                        <li>zakres odpowiedzialności spedytora</li>\n' +
            '                                        <li>najczęściej popełniane błędy w umowach spedycji,</li>\n' +
            '                                        <li>skutki mylenia spedytora z przewoźnikiem</li>\n' +
            '                                        <li>czego klient ma prawo wymagać od spedytora a czego raczej nie</li>\n' +
            '                                        <li>czy zleceniodawca spedytora może domagać się dowodu, że spedytor zapłacił</li>\n' +
            '                                        <li>przewoźnikowi?</li>\n' +
            '                                        <li>instrukcja wysyłkowa, ważne narzędzie pracy spedytora</li>\n' +
            '                                        <li>reklamacje z tytułu umowy spedycji, kiedy spedytor odpowiada jak przewoźnik</li>\n' +
            '                                        <li>problem fałszywych przewoźników i podwykonawców – procedury zaradcze</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Ubezpieczenia – co trzeba wiedzieć\n' +
            '                                    <ul>\n' +
            '                                        <li>ograniczenia odpowiedzialności przewoźnika co do wysokości odszkodowania w\n' +
            '                                            przewozach międzynarodowych\n' +
            '                                        </li>\n' +
            '                                        <li>OC Przewoźnika ubezpiecza przewoźnika a nie ładunek</li>\n' +
            '                                        <li>można mieć rację ale nie mieć pieniędzy tzw. „ciche klauzule”</li>\n' +
            '                                        <li>zakres ubezpieczenia CARGO</li>\n' +
            '                                        <li>czy CARGO zapłaci gdy przewoźnik odpowiada za szkodę?</li>\n' +
            '                                        <li>co spedytor obowiązkowo musi sprawdzić w ubezpieczeniu przewoźnika (a z\n' +
            '                                            reguły tego nie robi)?\n' +
            '                                        </li>\n' +
            '                                        <li>najczęstsze powody odmowy wypłaty odszkodowania z polisy ubezpieczeniowej\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Odpowiedzialność i dochodzenie roszczeń z tytułu umowy przewozu towarów –\n' +
            '                                    krajowe i międzynarodowe (Prawo przewozowe, Konwencja CMR).\n' +
            '                                    <ul>\n' +
            '                                        <li>podstawy prawne,</li>\n' +
            '                                        <li>zakres odpowiedzialności przewoźnika,</li>\n' +
            '                                        <li>niedyspozytywny charakter klauzul Konwencji CMR i Prawa przewozowego (nie podlegają woli stron np. w Konwencji CMR kary umowne za opóźnienie w dostawie są niedopuszczalne),</li>\n' +
            '                                        <li>kto ma obowiązek załadować, rozładować towar, kto ma mocować towar na pojeździe, podstawy prawne, wysokie kary dla nadawców i załadowców</li>\n' +
            '                                        <li>umowa to podstawa a list przewozowy jest dowodem tej umowy,</li>\n' +
            '                                        <li>najczęściej popełniane błędy w umowach przewozu i w listach przewozowych,</li>\n' +
            '                                        <li>zasady poprawnego wypełniania listu przewozowego w przewozach międzynarodowych – ważne!</li>\n' +
            '                                        <li>najczęstsze przyczyny powstawania szkód w przewozach drogowych,</li>\n' +
            '                                        <li>przewoźnik nie odpowiada za wszystkie szkody powstałe w transporcie – przesłanki zwalniające (szczegółowe omówienie)</li>\n' +
            '                                        <li>postępowanie w przypadku stwierdzenia uszkodzenia, ubytku, utraty lub opóźnienia w dostawie: co powinien zrobić przewoźnik, a co odbiorca (oraz spedytor),</li>\n' +
            '                                        <li>najczęstsze błędy proceduralne w dochodzeniu roszczeń, które skutkują utratą odszkodowania</li>\n' +
            '                                        <li>przykłady praktyczne,</li>\n' +
            '                                        <li>jak powinna wyglądać poprawnie złożona reklamacja,</li>\n' +
            '                                        <li>jak powinna wyglądać prawidłowa odpowiedź przewoźnika.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Pytania, dyskusja\n' +
            '                                </li>',
        lecturerDescription: 'mgr Andrzej Sikorski - zawodowy trener, absolwent Uniwersytetu Gdańskiego a także\n' +
            'renomowanego Francuskiego Instytutu Zarządzania. <br/>Ma za sobą ponad 30 lat pracy w spedycji i\n' +
            'transporcie międzynarodowym. <br/>Od kilkunastu lat szkoli logistyków, spedytorów i przewoźników.\n' +
            'Jest autorem książki „Transport i spedycja międzynarodowa w handlu zagranicznym.”',
    },
    {
        slug: 'przewozy-kontenerowe-i-drobnicowe-spedycja-i-transport-morski',
        title: 'Przewozy kontenerowe i drobnicowe - spedycja i transport morski',
        description:'Celem szkolenia jest:\n' +
            '                            <ul>\n' +
            '                                <li>zapoznanie słuchaczy ze specyfiką transportu i spedycji morskiej,</li>\n' +
            '                                <li>omówienie charakterystycznych dla transportu morskiego pojęć, dokumentów, a także\n' +
            '                                    systemu stawek za przewóz morski i opłat portowych,\n' +
            '                                </li>\n' +
            '                                <li>pokazanie jak składać reklamacje dotyczące przewoźnika lub spedytora morskiego,</li>\n' +
            '                                <li>zapoznanie uczestników z zagadnieniami ubezpieczeń morskich i dlaczego akurat w\n' +
            '                                    przypadku transportu morskiego tak ważne jest ubezpieczenie ładunku CARGO.\n' +
            '                                </li>\n' +
            '                            </ul>\n' +
            '                            Korzyści dla uczestników:\n' +
            '                            <ul>\n' +
            '                                <li>znajomość terminologii i dokumentacji charakterystycznej dla transportu\n' +
            '                                    morskiego,\n' +
            '                                </li>\n' +
            '                                <li>skuteczne składanie reklamacji dotyczących transportu morskiego,</li>\n' +
            '                                <li>zapoznanie się ze specyfiką ubezpieczeń CARGO.</li>\n' +
            '                            </ul>',
        program: '<li>Żegluga:\n' +
            '                                    <ul>\n' +
            '                                        <li>przewoźnik morski (armator), przewóz ładunku, statek morski,</li>\n' +
            '                                        <li>wyjaśnienie pojęć takich jak: armator, przewoźnik, NVOCC, frachtujący,\n' +
            '                                            shipper,\n' +
            '                                        </li>\n' +
            '                                        <li>agent, makler, FCL/LCL i wiele innych,</li>\n' +
            '                                        <li>żegluga liniowa - umowa bukingowa, linie żeglugowe.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Żegluga liniowa - przewozy kontenerowe i konwencjonalne:\n' +
            '                                    <ul>\n' +
            '                                        <li>przewozy kontenerowe "drzwi-drzwi" (przewozy pełno kontenerowe\n' +
            '                                            FCL, przewozy drobnicowe - LCL),\n' +
            '                                        </li>\n' +
            '                                        <li>sprzęt kontenerowy,</li>\n' +
            '                                        <li>załadunek i mocowanie towarów w kontenerze, opakowania transportowe,</li>\n' +
            '                                        <li>przewozy konwencjonalne.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Obowiązkowe ważenie kontenerów (VGM) , przepisy – stan aktualny:\n' +
            '                                    <ul>\n' +
            '                                        <li>kogo dotyczą nowe obowiązki a kto jest zwolniony,</li>\n' +
            '                                        <li>metoda 1, ważenie całych kontenerów,</li>\n' +
            '                                        <li>metoda 2, ważenie ładunku i dodanie tary kontenera (tylko zarejestrowany załadowca),</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Koszty transportu morskiego:\n' +
            '                                    <ul>\n' +
            '                                        <li>koszty transportu w żegludze liniowej,</li>\n' +
            '                                        <li>stawka frachtowa: LILO, LIFO, FILO, FIFO, dodatki frachtowe: BAF, CAF, congestion, PSS, inne,</li>\n' +
            '                                        <li>koszty portowe i koszty przewozów lądowych: THC, demurrage, detention, storage, inne.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Konosament i inne dokumenty:\n' +
            '                                    <ul>\n' +
            '                                        <li>wystawianie konosamentu (bill of lading) i jak czytać konosament,</li>\n' +
            '                                        <li>rodzaje konosamentu: załadowania i przyjęcia do załadowania (shipped, on board), (received for shipment), konosament czysty i nieczysty (clean bill of lading) (foul, claused bill of lading), inne,</li>\n' +
            '                                        <li>rola konosamentu w umowie handlowej (akredytywa, inkaso dokumentowe),</li>\n' +
            '                                        <li>prezentacja konosamentu - zwalnianie ładunku w porcie przeznaczenia (wystarczy jeden oryginalny konosament czy trzeba wszystkie oryginały?).</li>\n' +
            '                                        <li>telex release, sea waybill vs bill of lading</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Szkody w transporcie morskim i ubezpieczenia morskie:\n' +
            '                                    <ul>\n' +
            '                                        <li>przyczyny szkód w towarach w transporcie morskim</li>\n' +
            '                                        <li>wyjątkowo szeroki zakres przesłanek zwalniających przewoźnika morskiego od odpowiedzialności za ubytek, uszkodzenie czy utratę towaru (za opóźnienie w</li>\n' +
            '                                        <li>dostawie przewoźnik morski co do zasady nie odpowiada)</li>\n' +
            '                                        <li>ograniczenia wysokości kwoty odszkodowania w przypadku uznania odpowiedzialności przewoźnika morskiego za szkodę w towarze</li>\n' +
            '                                        <li>awaria wspólna i szczególna (general average and particular average)</li>\n' +
            '                                        <li>ubezpieczenie CARGO,</li>\n' +
            '                                        <li>reklamacje do przewoźnika morskiego.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Pytania, dyskusja\n' +
            '                                </li>',
        lecturerDescription: 'mgr Andrzej Sikorski - zawodowy trener, absolwent Uniwersytetu Gdańskiego a także\n' +
            'renomowanego Francuskiego Instytutu Zarządzania. <br/>Ma za sobą ponad 30 lat pracy w spedycji i\n' +
            'transporcie międzynarodowym. <br/>Od kilkunastu lat szkoli logistyków, spedytorów i przewoźników.\n' +
            'Jest autorem książki „Transport i spedycja międzynarodowa w handlu zagranicznym.”',
    },
    {
        slug: 'intrastat-teoria-i-praktyka',
        title: 'Intrastat – teoria i praktyka',
        description: 'Teoria i praktyka wypełniania dokumentów dotyczących obrotu\n' +
            '                            towarowego między krajami Unii Europejskiej.\n' +
            '                            <br/>\n' +
            '                            <br/>\n' +
            '                            Cel szkolenia:\n' +
            '                            <br/>\n' +
            '                            Celem zajęć jest przekazanie pełnej i aktualnej wiedzy dotyczącej\n' +
            '                            składania deklaracji statystycznych Intrastat w obrocie towarowym w\n' +
            '                            UE nałożonym na podmioty gospodarcze 1 maja 2004 roku, czyli od\n' +
            '                            wejścia naszego kraju do Unii Europejskiej.',
        program: '<li>Obowiązek sprawozdawczy dla podmiotów dokonujących WNT i\n' +
            '                                    WDT\n' +
            '                                    <ul>\n' +
            '                                        <li>osoby zobowiązane, osoby zobowiązane a osoby trzecie, progi\n' +
            '                                            statystyczne 2016, moment powstania obowiązku sprawozdawczego,\n' +
            '                                            wygaśnięcie obowiązku deklaracyjnego.\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Zasady generalne deklaracji INTRASTAT\n' +
            '                                    <ul>\n' +
            '                                        <li>Definicje</li>\n' +
            '                                        <li>Dostawy i nabycia wewnątrzwspólnotowe podlegający zgłoszeniu</li>\n' +
            '                                        <li>Obowiązek sprawozdawczy</li>\n' +
            '                                        <li>Wyłączenia przedmiotowe i podmiotowe z obowiązku sprawozdawczego</li>\n' +
            '                                        <li>System uproszczeń</li>\n' +
            '                                        <li>Terminy – okres sprawozdawczy, zgłoszenia częściowe, zgłoszenia\n' +
            '                                            elektroniczne (program ist@t)\n' +
            '                                        </li>\n' +
            '                                        <li>Istota zgłoszenia (forma, przeznaczenie, metody, zakres informacyjny, obrót\n' +
            '                                            zerowy)\n' +
            '                                        </li>\n' +
            '                                        <li>System upomnień i kar w przypadkach niewywiązywania się osób zobowiązanych z\n' +
            '                                            obowiązku sprawozdawczego\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Szczególne przypadki postępowania</li>\n' +
            '                                <li>Korekty zgłoszeń w zakresie warunków i terminów wymagalności\n' +
            '                                    oraz zwolnienia z obowiązku korekty\n' +
            '                                    <ul>\n' +
            '                                        <li>sposoby i przypadki korygowania deklaracji</li>\n' +
            '                                        <li>korekta pól finansowych</li>\n' +
            '                                        <li>korekta bieżąca</li>\n' +
            '                                        <li>nota korygująca</li>\n' +
            '                                        <li>terminy korekt.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Dostarczanie deklaracji poprzez stronę internetową, pocztą\n' +
            '                                    elektroniczną oraz innymi nowymi i alternatywnymi kanałami\n' +
            '                                    dostępu\n' +
            '                                </li>\n' +
            '                                <li>\n' +
            '                                    Podsystem Danych Referencyjnych i sposób rejestracji , aplikacja\n' +
            '                                    E-klient\n' +
            '                                </li>\n' +
            '                                <li>Zmiany w systemie INTRASTAT\n' +
            '                                    <ul>\n' +
            '                                        <li>zmiana taryfy celnej</li>\n' +
            '                                        <li>AIS (CELINA) – zintegrowana obsługa INTRASTAT</li>\n' +
            '                                        <li>PUESC – pojedynczy punkt dostępu do Systemu Informacyjnego Służby Celnej\n' +
            '                                            SISC\n' +
            '                                        </li>\n' +
            '                                        <li>e INTRASTAT (AIS)</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>\n' +
            '                                    Praktyczne zajęcia z wypełniania zgłoszenia Intrastat\n' +
            '                                </li>\n' +
            '                                <li>\n' +
            '                                    Problemy indywidualne.\n' +
            '                                </li>',
        lecturerDescription: ''
    },
    {
        slug: 'klasyfikacja-taryfowa-towarow',
        title: 'Klasyfikacja taryfowa towarów szkolenie\n' +
            '\n' +
            'teoretyczno-praktyczne',
        description: '',
        program: '<li>Podstawy prawne klasyfikacji taryfowej towarów w handlu\n' +
            '                                    zagranicznym\n' +
            '                                    <ul>\n' +
            '                                        <li>przepisy prawa międzynarodowego</li>\n' +
            '                                        <li>taryfikacja towarów w przepisach prawa Unii Europejskiej i polskim\n' +
            '                                            prawie celnym\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>System Zharmonizowany i Nomenklatura Scalona oraz Wspólna\n' +
            '                                    Taryfa Celna\n' +
            '                                    <ul>\n' +
            '                                        <li>budowa i zasady klasyfikacji HS i CN</li>\n' +
            '                                        <li>struktura Taryfy Celnej Unii Europejskiej</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Ogólne Reguły Interpretacji Nomenklatury Scalonej\n' +
            '                                    <ul>\n' +
            '                                        <li>znaczenie ORINS w procesie klasyfikowania towarów do właściwych pozycji</li>\n' +
            '                                        <li>szczegółowe omówienie kolejnych reguł z uwzględnieniem ich prawidłowego używania w taryfikowaniu towarów</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Źródła wiedzy o klasyfikacji taryfowej zawarte w orzecznictwie\n' +
            '                                    TSUE, rozporządzeniach Komisji UE i notach wyjaśniających do HS\n' +
            '                                    i CN publikowanych przez WCO\n' +
            '                                    <ul>\n' +
            '                                        <li>wyroki Trybunału Sprawiedliwości UE w sprawach dotyczących klasyfikacji\n' +
            '                                            taryfowej\n' +
            '                                        </li>\n' +
            '                                        <li>rozporządzenia klasyfikacyjne Komisji</li>\n' +
            '                                        <li>Noty wyjaśniające do HS i CN</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Coroczne zmiany w klasyfikacji towarowej wynikające ze zmian\n' +
            '                                    Tabeli Stawek Celnych – rola tablic korelacyjnych\n' +
            '                                    <ul>\n' +
            '                                        <li>przykłady zmian w Taryfie Celnej obowiązujących od początku roku</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wiążąca Informacja Taryfowa\n' +
            '                                    <ul>\n' +
            '                                        <li>rola i znaczenie WIT</li>\n' +
            '                                        <li>zmiany w stosowaniu i wnioskowaniu wynikające z pakietu Unijnego Kodeksu Celnego wprowadzone od 1 maja 2016 r.</li>\n' +
            '                                        <li>przegląd WIT wydanych w Polsce i UE z zastosowaniem aplikacji ISZTAR i EBTI</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Praktyczne zadania taryfikacyjne przygotowane przez trenera ze\n' +
            '                                    szczegółowym omówieniem klasyfikacji oraz omówienie\n' +
            '                                    przypadków zgłoszonych przez uczestników szkolenia\n' +
            '                                </li>',
        lecturerDescription: ''
    },
    {
        slug: 'czas-pracy-kierowcow',
        title: 'Czas pracy kierowców – zmiany, stan aktualny',
        description: 'Szkolenie dla spedytorów i logistyków',
        program: '<li>Unormowania prawne\n' +
            '                                    <ul>\n' +
            '                                        <li>\n' +
            '                                            obowiązki przedsiębiorców, pracodawców i kierowców - jak w\n' +
            '                                            praktyce stosować znowelizowane w 2013 r. przepisy.\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Ustawa o czasie pracy kierowców:\n' +
            '                                    <ul>\n' +
            '                                        <li>zakres przedmiotowy ustawy,</li>\n' +
            '                                        <li>definicje dotyczące czasu pracy kierowców,</li>\n' +
            '                                        <li>dyżur, przerwy w pracy, odpoczynek,</li>\n' +
            '                                        <li>systemy czasu pracy kierowców,</li>\n' +
            '                                        <li>praca w porze nocnej i w niedzielę i święta,</li>\n' +
            '                                        <li>systemy rozliczania i ewidencja czasu pracy kierowców zatrudnionych na umowę\n' +
            '                                            o pracę i kierowców pracujących na własny rachunek – zgodnie ze zmianami z\n' +
            '                                            16 lipca 2013 roku.\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Przepisy Rozporządzenia 561/2006 Parlamentu Europejskiego i Rady dot. czasu jazdy i\n' +
            '                                    odpoczynków kierowców – ich wpływ na czas pracy kierowcy:\n' +
            '                                    <ul>\n' +
            '                                        <li>wyłączenia na terytorium RP przewozów wykonywanych określonymi pojazdami,\n' +
            '                                        </li>\n' +
            '                                        <li>zasady użytkowanie urządzeń kontrolno-pomiarowych (tachograf analogowy i\n' +
            '                                            cyfrowy),\n' +
            '                                        </li>\n' +
            '                                        <li>zasady użytkowania wykresówek i wydruków z tachografu cyfrowego (np. zapisy\n' +
            '                                            ręczne),\n' +
            '                                        </li>\n' +
            '                                        <li>czas jazdy dzienny, tygodniowy, dwutygodniowy,</li>\n' +
            '                                        <li>przerwy w prowadzeniu pojazdu,</li>\n' +
            '                                        <li>odpoczynek dzienny, tygodniowy,</li>\n' +
            '                                        <li>zaświadczenia za okres nieprowadzenia pojazdu,</li>\n' +
            '                                        <li>funkcjonowanie systemu tachografu cyfrowego,</li>\n' +
            '                                        <li>sczytywanie i archiwizacja zapisów z tachografu cyfrowego,</li>\n' +
            '                                        <li>obowiązki kierowcy i przedsiębiorcy związane z właściwym wypełnianiem\n' +
            '                                            zaświadczeń o okresie nieprowadzenia pojazdu oraz ręcznym zapisem.\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Sankcje za naruszenia norm czasu jazdy, postoju oraz stosowania urządzeń\n' +
            '                                    rejestrujących:\n' +
            '                                    <ul>\n' +
            '                                        <li>odpowiedzialność przedsiębiorstw i kierowców,</li>\n' +
            '                                        <li>mechanizmy powstawania naruszeń w zakresie okresów prowadzenia pojazdów i\n' +
            '                                            użytkowania urządzeń.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Dokumenty wymagane podczas kontroli drogowej transportu drogowego.</li>\n' +
            '                                <li>Panel dyskusyjny - przykłady, analiza przypadków, pytania.</li>',
        lecturerDescription: ''
    },
    {
        slug: 'zasady-prawidlowego-zaladunku-i-rozmieszczania-towaru-na-pojezdzie',
        title: 'Zasady prawidłowego załadunku i rozmieszczenia towaru na\n' +
            'pojeździe - nowelizacja ustawy o transporcie drogowym i\n' +
            'ustawy Prawo przewozowe (oraz innych ustaw)',
        description: '',
        program: '<li>Podstawy prawne\n' +
            '                                    <ul>\n' +
            '                                        <li>Elementy Umowy o międzynarodowym przewozie towarów (Konwencji CMR) oraz\n' +
            '                                            Ustawy z dnia 15-11-1984 r. PRAWO PRZEWOZOWE a także ustawa o transporcie\n' +
            '                                            drogowym.\n' +
            '                                        </li>\n' +
            '                                        <li>Obowiązki uczestników przewozu (załadowcy, przewoźnika i kierowcy) –\n' +
            '                                            nowelizacja z dnia 16 września 2011 r.\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Załadunek pojazdu, a bezpieczeństwo przewozu ładunku.\n' +
            '\n' +
            '                                </li>\n' +
            '                                <li>\n' +
            '                                    Ograniczenia w ładowaniu pojazdów.\n' +
            '                                    <ul>\n' +
            '                                        <li>Ograniczenia wynikające z konstrukcji dróg, konstrukcji pojazdów oraz z praw\n' +
            '                                            fizyki.\n' +
            '                                        </li>\n' +
            '                                        <li>Siły działające na pojazd i ładunek (ciężar pojazdu, siła bezwładności i\n' +
            '                                            odśrodkowa).\n' +
            '                                        </li>\n' +
            '                                        <li>Siły utrzymujące ładunek na pojeździe (siła tarcia, zabezpieczenia i\n' +
            '                                            napinająca).\n' +
            '                                        </li>\n' +
            '                                        <li>Stateczność ładunku.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wymagania ładunkowe wobec pojazdów.\n' +
            '                                    <ul>\n' +
            '                                        <li>Dobór nadwozia do przewożonego ładunku.</li>\n' +
            '                                        <li>Wytrzymałość ścian skrzyni ładunkowej, dopuszczalne naciski na podłogę\n' +
            '                                            skrzyni, właściwy rozkład\n' +
            '                                        </li>\n' +
            '                                        <li>obciążenia skrzyni i punkty mocowania ładunku.</li>\n' +
            '                                        <li>Zasady ustalania środka ciężkości ładunku.</li>\n' +
            '                                        <li>Plan ładowania pojazdu i umiejętność jego wykorzystania.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Rodzaje zabezpieczeń ładunków.</li>\n' +
            '                                <li>Środki mocujące dla zabezpieczenia ładunków.\n' +
            '                                    <ul>\n' +
            '                                        <li>Rodzaje środków mocujących (pasy, łańcuchy, liny, ochrony krawędzi,\n' +
            '                                            połączenia międzyścienne, pętle i kątowniki, siatki i plandeki, podkładki\n' +
            '                                            antypoślizgowe i poduszki wypełniające) i ich dobór do określonych ładunków.\n' +
            '                                        </li>\n' +
            '                                        <li>Budowa pasa mocującego i umiejętność posługiwania się nim w procesie\n' +
            '                                            mocowania ładunku.\n' +
            '                                        </li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Zalecenia IRU (międzynarodowej Unii Transportu Drogowego) w zakresie prawidłowego\n' +
            '                                    załadunku pojazdów.\n' +
            '                                    <ul>\n' +
            '                                        Zalecenia dotyczące przewozów uniwersalnych, przewozów kontenerów pojazdami\n' +
            '                                        uniwersalnymi,\n' +
            '                                        przewozów drewna, przewozów ładunków o znacznej długości, przewozów pojazdów,\n' +
            '                                        przewozów\n' +
            '                                        płyt i bębnów, przewozów ładunków masowych, przewozów towarów paczkowanych\n' +
            '                                        (drobnicy) oraz\n' +
            '                                        palet.\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>\n' +
            '                                    Odpowiedzi na pytania – dyskusja.\n' +
            '                                </li>',
        lecturerDescription: ''
    },
    {
        slug: 'co-sie-zmienia-w-przepisach-adr-od-01-01-2021',
        title: 'Co się zmienia w przepisach ADR od 01.01.2021',
        description: 'Celem szkolenia jest zapoznanie uczestników ze zmianami do Umowy europejskiej dotyczącej\n' +
            'międzynarodowego przewozu drogowego towarów niebezpiecznych (ADR), wchodzącymi w życie w\n' +
            'dniu 1 stycznia 2021 r oraz obowiązującymi (aktualizowanymi w 2020 roku) przepisami krajowymi.',
        program: '<li>Status i stan przepisów międzynarodowych- Aktualny stan międzynarodowych i krajowych\n' +
            '                                    przepisów o przewozie drogowym towarów niebezpiecznych,\n' +
            '                                    <ul>\n' +
            '                                        <li>Przepisy modelowe (Zalecenia ONZ, Podręcznik badań i kryteriów)</li>\n' +
            '                                        <li>Dyrektywy UE</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Aktualny stan odstępstw od umowy\n' +
            '                                    <ul>\n' +
            '                                        <li>Odstępstwa "pandemiczne"</li>\n' +
            '                                        <li>Pozostałe odstępstwa</li>\n' +
            '                                        <li>Przepisy przejściowe</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>ADR a sprawa "polska"\n' +
            '                                    <ul>\n' +
            '                                        <li>nieskorygowane błędy z poprzednich wersji przepisów</li>\n' +
            '                                        <li>zgodność "polskiego" ADR z wymaganiami prawa międzynarodowego</li>\n' +
            '                                        <li>Art. 13 ust 2 ustawy czyli problem "właściciela"</li>\n' +
            '                                        <li>Znaki, symbole i nalepki</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Interpretacje oficjalne ADR\n' +
            '                                    <ul>\n' +
            '                                        <li>przegląd interpretacji z okresu 2019 - 2020</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>ADR 2021: Przegląd zmian\n' +
            '                                    <ul>\n' +
            '                                        <li>Zmiana nazwy</li>\n' +
            '                                        <li>Nowa klasyfikacja towarów i odpadów.</li>\n' +
            '                                        <li>Zmiany w zakresie przewozu towarów radioaktywnych</li>\n' +
            '                                        <li>Nowe przepisy dotyczące transportu baterii i urządzeń litowych.</li>\n' +
            '                                        <li>Klasyfikacja uszkodzonych baterii litowych na krytyczne - bezkrytyczne.</li>\n' +
            '                                        <li>Nowe przepisy specjalne (SV 636/376/670)</li>\n' +
            '                                        <li>Nowe przepisy dotyczące opakowań i podwójne kodowanie IBC (ASP)</li>\n' +
            '                                        <li>Zmiany w zakresie dokumentacji przewozowej</li>\n' +
            '                                        <li>Okresy przejściowe i odstępstwa</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>ADR 2023: Informacje o kierunkach prac\n' +
            '                                </li>\n' +
            '                                <li>Dyskusja, pytania i odpowiedz\n' +
            '                                </li>',
        lecturerDescription: ''
    },
    {
        slug:'nowe-wymagania-dla-magazynow-odpadow-od-01-01-2021',
        title:'Szkolenie - nowe wymagania dla magazynowania odpadów\n' +
            '\n' +
            'od 01.01.2021',
        description:'Od 1 stycznia 2021 roku wchodzą w życie zmodyfikowane wymagania odnośnie magazynowania\n' +
            'odpadów. Szkolenie prezentuje wprowadzone nowymi regulacjami wymagania oraz wskazuje\n' +
            'sposoby ich realizacji.',
        program: '<li>Podstawy prawne dla gospodarki odpadami\n' +
            '                                    <ul>\n' +
            '                                        <li>wymagania ustawowe</li>\n' +
            '                                        <li>Podstawowe obowiązki w zakresie magazynowania</li>\n' +
            '                                        <li>wymagania ppoż.</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Co jest czym?\n' +
            '                                    <ul>\n' +
            '                                        <li>odpady w świetle przepisów</li>\n' +
            '                                        <li>opady „obojętne”</li>\n' +
            '                                        <li>produkty uboczne</li>\n' +
            '                                        <li>odpady będący produktami chemicznymi</li>\n' +
            '                                        <li>odpady będące towarami niebezpiecznymi (ADR)</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Metody ograniczania oddziaływania odpadów\n' +
            '                                    <ul>\n' +
            '                                        <li>dobór opakowań</li>\n' +
            '                                        <li>wymagania minimalne w zakresie opakowań</li>\n' +
            '                                        <li>metody przeciwdziałania uwolnieniu</li>\n' +
            '                                        <li>metody zabezpieczania środowiska</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Nowe regulacje (01.01.2021)\n' +
            '                                    <ul>\n' +
            '                                        <li>kiedy stosujemy wymagania?</li>\n' +
            '                                        <li>Które odpady możemy magazynować jak poprzednio?</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wstępne magazynowanie odpadów\n' +
            '                                    <ul>\n' +
            '                                        <li>kiedy ma zastosowanie?</li>\n' +
            '                                        <li>do jakich ilości odpadów można stosować wstępne magazynowanie?</li>\n' +
            '                                        <li>gdzie wstępnie magazynować?</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wymagania dla miejsc magazynowania\n' +
            '                                    <ul>\n' +
            '                                        <li>oznakowanie</li>\n' +
            '                                        <li>Wydzielenia</li>\n' +
            '                                        <li>Wymagania dla opakowań i pojemników</li>\n' +
            '                                        <li>Ustalanie pojemności</li>\n' +
            '                                        <li>Metody zabezpieczania</li>\n' +
            '                                        <li>Metody aktywnej prewencji (oczyszczanie)</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wymagania dla pojemników i opakowań\n' +
            '                                    <ul>\n' +
            '                                        <li>Etykietowanie</li>\n' +
            '                                        <li>Wymagania dla warunków składowania</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wymagania dla odpadów zakaźnych i medycznych\n' +
            '                                    <ul>\n' +
            '                                        <li>identyfikacja odpadów</li>\n' +
            '                                        <li>Klasyfikacja transportowa odpadów zakaźnych</li>\n' +
            '                                        <li>Wymagania do magazynów</li>\n' +
            '                                        <li>Reżim czasowy</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Przepisy przejściowe\n' +
            '                                    <ul>\n' +
            '                                        <li>terminy dostosowania wymagań dla obecnych miejsc składowania</li>\n' +
            '                                        <li>Kto za co odpowiada</li>\n' +
            '                                    </ul>\n' +
            '                                </li>\n' +
            '                                <li>Wymagania ppoż. dla składowania odpadów</li>\n' +
            '                                <li>Pytania uczestników</li>',
        lecturerDescription: ''
    }
];

export const trainingService = {
    getBySlug,
    getAll
}

function getBySlug(s) {
    return new Promise(resolve => resolve(trainings.find(({slug}) => slug === s)));
}

export function getAll() {
    return new Promise(resolve => resolve(trainings));
}

