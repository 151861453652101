import React from 'react'
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

const useStyles = makeStyles({
    listStyle: {
        '& li': {
            fontWeight: 400,
            lineHeight: 2
        },
        '& > li, & > li > ul': {
            paddingBottom: '15px',
            fontWeight: 600
        }
    },
    content: {
        margin: '40px'
    }
})

function Privacy() {
    const classes = useStyles();

    return (
        <Box className={classes.content}><h3>Polityka prywatności oraz plików cookies („Polityka prywatności”)</h3>
            <Typography variant="body1" gutterBottom>Niniejsza Polityka prywatności jest wyrazem dbałości o prawa osób odwiedzających
                serwis oraz
                korzystających z usług oferowanych za jego pośrednictwem. Jest ona również spełnieniem obowiązku
                informacyjnego wnikającego z art. 13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) nr 2016/679 z
                dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i
                w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
                ochronie danych osobowych) (Dz. Urz. UE L119 z 4.05.2016, str. 1) (dalej RODO).
            </Typography>
            <Typography variant="body1" gutterBottom>Właściciel serwisu
                przywiązuje szczególną wagę do poszanowania prywatności użytkowników serwisu. Dane pozyskane w ramach
                serwisu są szczególnie chronione i zabezpieczone przed dostępem osób nieupoważnionych. Polityka
                prywatności jest udostępniana wszystkim podmiotom zainteresowanym. Serwis ma charakter
                otwarty.
            </Typography>
            <Typography variant="body1" gutterBottom>Właściciel serwisu zapewnia, że jego nadrzędnym celem jest zapewnienie osobom
                korzystającym z serwisu
                ochrony prywatności na poziomie co najmniej odpowiadającym wymogom stawianym przez obowiązujące przepisy
                prawa, a w szczególności przez przepisy RODO oraz przez ustawę z dnia 18 lipca 2002 r. o świadczeniu
                usług drogą elektroniczną.
            </Typography>
            <Typography variant="body1" gutterBottom>Właściciel serwisu może zbierać dane
                osobowe oraz dane o innym
                charakterze. Zbieranie tych danych odbywa się, w zależności od ich charakteru – automatycznie lub na
                skutek działań osób odwiedzających serwis.
            </Typography>
            <Typography variant="body1">Każda osoba
                korzystająca w jakikolwiek sposób z serwisu
                akceptuje wszelkie zasady zawarte w niniejszej Polityce prywatności. Właściciel serwisu zastrzega sobie
                możliwość wprowadzania zmian w niniejszym dokumencie.
            </Typography>
            <ol className={classes.listStyle} type="I">
                <li>Informacje ogólne, pliki cookies
                    <ol type="1">
                        <li>Właścicielem i operatorem serwisu jest Abc Sikorski z siedzibą w Warszawa, adres: ul.
                            Igańska 26/45 , 04-083 Warszawa, wpisana do rejestru przedsiębiorców Krajowego Rejestru
                            Sądowego, prowadzonego przez Sąd Rejonowy w , Wydział Gospodarczy KRS, pod numerem KRS: ,
                            numer NIP: 1130103644, numer REGON: 010854332. Zgodnie z przepisami RODO, właściciel serwisu
                            jest również Administratorem Danych Osobowych użytkowników serwisu („Administrator”).
                        </li>
                        <li>W ramach wykonywanych czynności Administrator korzysta z plików cookies w ten sposób, że
                            obserwuje i analizuje ruch na stronach serwisu, jak również podejmuje działania
                            remarketingowe, jednakże w ramach tych czynności Administrator nie przetwarza danych
                            osobowych w rozumieniu RODO.
                        </li>
                        <li>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach serwisu i ich zachowaniu w
                            następujący sposób:
                            <ol>
                                <li>serwis zbiera automatycznie informacje, które zawarte są w plikach cookies.</li>
                                <li>poprzez dobrowolnie wprowadzane przez użytkowników serwisu dane, w formularzach
                                    dostępnych na stronach serwisu.
                                </li>
                                <li>poprzez automatyczne gromadzenie logów serwera www przez operatora hostingowego.
                                </li>
                            </ol>
                        </li>
                        <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki
                            tekstowe, które przechowywane są w urządzeniu końcowym użytkownika serwisu i przeznaczone są
                            do korzystania ze stron internetowych serwisu. Cookies zazwyczaj zawierają nazwę strony
                            internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz
                            unikalny numer.
                        </li>
                        <li>Podczas wizyty w serwisie automatycznie zbierane mogą być dane użytkowników serwisu,
                            dotyczące danej wizyty użytkownika w serwisie i obejmujące m. in. adres IP, rodzaj
                            przeglądarki internetowej, nazwę domeny, liczbę odsłon, rodzaj systemu operacyjnego, wizyty,
                            rozdzielczość ekranu, liczbę kolorów ekranu, adresy stron internetowych, z których nastąpiło
                            wejście do serwisu, czas korzystania z serwisu. Dane te nie są danymi osobowymi, nie
                            pozwalają także na zidentyfikowanie osoby korzystającej z serwisu.
                        </li>
                        <li>W ramach serwisu mogą występować odnośniki do innych stron internetowych. Właściciel serwisu
                            nie ponosi odpowiedzialności za zasady zachowania prywatności obowiązujące na tych stronach.
                            Jednocześnie właściciel serwisu zachęca do zapoznania się przez użytkownika serwisu z
                            polityką prywatności ustaloną w ramach tych stron internetowych. Niniejsza Polityka
                            prywatności nie odnosi się do innych serwisów internetowych.
                        </li>
                        <li>Podmiotem zamieszczającym na urządzeniu końcowym użytkownika serwisu pliki cookies oraz
                            uzyskującym do nich dostęp jest właściciel serwisu.
                        </li>
                        <li>Pliki cookies wykorzystywane są w celu:
                            <ol>
                                <li>dostosowania zawartości stron internetowych serwisu do preferencji użytkownika
                                    serwisu oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki
                                    te pozwalają rozpoznać urządzenie użytkownika serwisu i odpowiednio wyświetlić
                                    stronę internetową, dostosowaną do jego indywidualnych potrzeb,
                                </li>
                                <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy serwisu
                                    korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i
                                    zawartości,
                                </li>
                                <li>utrzymanie sesji użytkownika serwisu (po zalogowaniu), dzięki której nie musi on na
                                    każdej podstronie serwisu ponownie wpisywać loginu i hasła.
                                </li>
                            </ol>
                        </li>
                        <li>W ramach serwisu stosowane są następujące rodzaje plików cookies:
                            <ol>
                                <li>„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach
                                    serwisu, np. uwierzytelniające pliki cookies,
                                </li>
                                <li>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do
                                    wykrywania nadużyć,
                                </li>
                                <li>„wydajnościowe” pliki cookies, wykorzystywane do pozyskiwania informacji o sposobie
                                    korzystania ze stron internetowych serwisu przez użytkowników serwisu,
                                </li>
                                <li>„reklamowe” pliki cookies, umożliwiające dostarczanie użytkownikom serwisu treści
                                    reklamowych bardziej dostosowanych do ich zainteresowań,
                                </li>
                                <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez
                                    użytkownika serwisu ustawień i dostosowanie serwisu do użytkownika serwisu, np. w
                                    zakresie wybranego języka.
                                </li>
                            </ol>
                        </li>
                        <li>W ramach serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session
                            cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi,
                            przechowywanymi w urządzeniu końcowym do czasu opuszczenia strony internetowej, wylogowania
                            się przez użytkownika serwisu lub wyłączenia oprogramowania (przeglądarki internetowej).
                            „Stałe” pliki cookies przechowywane są w urządzeniu końcowym użytkownika serwisu przez czas,
                            który jest określony w parametrach plików cookies lub do czasu ich usunięcia przez
                            użytkownika serwisu.
                        </li>
                        <li>W przeważającej ilości przypadków oprogramowanie służące do przeglądania stron internetowych
                            domyślnie zezwala na przechowywanie plików cookies w urządzeniu końcowym użytkownika
                            serwisu. Użytkownicy serwisu mają możliwość dokonania w wybranym przez siebie momencie zmian
                            ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w opcjach
                            przeglądarki internetowej (oprogramowania) m. in w sposób, który uniemożliwi automatyczną
                            obsługę plików cookies bądź wymusi informowanie użytkownika serwisu o każdorazowym
                            zamieszczeniu plików cookies w jego urządzeniu. Szczegółowe informacje o możliwości i
                            sposobach obsługi plików cookies dostępne są w ustawieniach przeglądarki internetowej.
                        </li>
                        <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
                            stronach internetowych serwisu.
                        </li>
                        <li>Pliki cookies zamieszczane w urządzeniu końcowym użytkownika serwisu wykorzystywane mogą być
                            również przez współpracujących z właścicielem serwisu reklamodawców oraz partnerów.
                        </li>
                    </ol>
                </li>
                <li>Przetwarzanie danych osobowych, informacje o formularzach
                    <ol>
                        <li>Dane osobowe użytkowników serwisu mogą być przetwarzane przez Administratora:
                            <ol className="type-a">
                                <li>w przypadku gdy użytkownik serwisu wyrazi na to zgodę w zamieszczonych w serwisie
                                    formularzach, w celu podjęcia działań, których formularze te dotyczą (art. 6 ust. 1
                                    lit. a RODO) albo
                                </li>
                                <li>gdy przetwarzanie jest niezbędne do wykonania umowy, której stroną jest użytkownik
                                    serwisu (art. 6 ust.l lit. b RODO), w przypadku, gdy serwis umożliwia zawarcie umowy
                                    między Administratorem a użytkownikiem serwisu.
                                </li>
                            </ol>
                        </li>
                        <li>W ramach serwisu dochodzi do przetwarzania danych osobowych, które są wyłącznie dobrowolnie
                            podane przez użytkowników serwisu. Administrator przetwarza dane osobowe użytkowników
                            serwisu wyłącznie w zakresie niezbędnym dla celów określonych w punkcie 1 lit. a i b powyżej
                            i przez okres niezbędny do realizacji tych celów, lub do momentu cofnięcia zgody przez
                            użytkownika serwisu. Brak podania danych przez użytkownika serwisu może w niektórych
                            sytuacjach skutkować niemożnością realizacji celów, dla których podanie danych jest
                            niezbędne.
                        </li>
                        <li>W ramach formularzy zamieszczonych w serwisie lub w celu wykonywania umów możliwych do
                            zawarcia w ramach serwisu mogą być zbierane następujące dane osobowe użytkownika serwisu:
                            imię, nazwisko, adres, adres poczty elektronicznej, numer telefonu, login, hasło.
                        </li>
                        <li>Dane zawarte w formularzach, przekazane Administratorowi przez użytkownika serwisu, mogą być
                            przez Administratora przekazywane podmiotom trzecim, współpracującym z Administratorem w
                            związku z realizacją przez niego celów określonych w punkcie 1 lit. a i b powyżej.
                        </li>
                        <li>Dane podane w formularzach zamieszczonych w serwisie są przetwarzane w celach wynikających z
                            funkcji konkretnego formularza, ponadto mogą być one wykorzystywane przez Administratora
                            także w celach archiwalnych i statystycznych. Zgoda osoby, której dane dotyczą, wyrażana
                            jest poprzez odznaczenie odpowiedniego okna w formularzu.
                        </li>
                        <li>Użytkownik serwisu, w przypadku gdy serwis posiada takie funkcjonalności, poprzez
                            zaznaczenie odpowiedniego okna w formularzu rejestracyjnym, może odmówić lub wyrazić zgodę
                            na otrzymywanie informacji handlowej za pomocą środków komunikacji elektronicznej, zgodnie z
                            ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r., Nr
                            144, poz. 1024 ze zm.). W przypadku gdy użytkownik serwisu wyraził zgodę na otrzymywanie
                            informacji handlowej za pomocą środków komunikacji elektronicznej, ma on prawo odwołania
                            takiej zgody w każdym czasie. Skorzystanie z prawa odwołania zgody na otrzymywanie
                            informacji handlowej jest realizowane poprzez wysłanie pocztą elektroniczną na adres
                            właściciela serwisu stosownego żądania wraz z podaniem imienia i nazwiska użytkownika
                            serwisu.
                        </li>
                        <li>Dane podane w formularzach mogą być przekazane podmiotom technicznie realizującym niektóre
                            usługi – w szczególności dotyczy to przekazywania informacji o posiadaczu rejestrowanej
                            domeny do podmiotów będących operatorami domeny internetowej (w szczególności Naukowa i
                            Akademicka Sieć Komputerowa j. b. r. – NASK), serwisów obsługujących płatności lub też innym
                            podmiotom, z którymi Administrator w takim zakresie współpracuje.
                        </li>
                        <li>Dane osobowe użytkowników serwisu są przechowywane w bazie danych, w której zastosowano
                            środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych zgodne z
                            wymaganiami określonymi w stosownych przepisach.
                        </li>
                        <li>Aby zapobiec powtórnej rejestracji osób, których udział w serwisie został zakończony ze
                            względu na niedozwolone korzystanie z usług serwisu, Administrator może odmówić usunięcia
                            danych osobowych niezbędnych do zablokowania możliwości ponownej rejestracji. Podstawą
                            prawną odmowy jest art. 19 ust. 2 pkt 3 w związku z art. 21 ust. 1 ustawy z dnia 18 lipca
                            2002 r. o świadczeniu usług drogą elektroniczną (t. j. z dnia 15 października 2013 r., Dz.
                            U. z 2013 r., poz. 1422). Odmowa usunięcia danych osobowych użytkowników serwisu przez
                            Administratora może również nastąpić w innych przypadkach przewidzianych przepisami prawa.
                        </li>
                        <li>W wypadkach prawem przewidzianych Administrator może udostępniać część danych osobowych
                            użytkowników serwisu osobom trzecim, dla celów związanych z ochroną praw osób trzecich.
                        </li>
                        <li>Administrator zastrzega sobie prawo wysyłania wszystkim użytkownikom serwisu listów
                            elektronicznych z powiadomieniami o ważnych zmianach w serwisie oraz o zmianach w niniejszej
                            Polityce prywatności. Administrator może wysyłać listy elektroniczne o charakterze
                            komercyjnym, zwłaszcza reklamy i inne treści o charakterze informacji handlowych, o ile
                            użytkownik serwisu wyraził na to zgodę. Reklamy i inne treści o charakterze informacji
                            handlowej mogą być też dołączane do listów przychodzących i wychodzących z konta
                            systemowego.
                        </li>
                    </ol>
                </li>
                <li>Uprawnienia użytkowników serwisu dotyczące ich danych osobowych.<Typography variant="body1">Zgodnie
                    z art. 15 - 22 RODO
                    każdemu użytkownikowi serwisu przysługują następujące prawa:</Typography>
                    <ol>
                        <li><strong>Prawo dostępu do danych (art. 15 RODO)</strong><Typography variant="body1">Osoba,
                            której dane dotyczą, jest
                            uprawniona do uzyskania od Administratora potwierdzenia, czy przetwarzane są dane osobowe
                            jej dotyczące, a jeżeli ma to miejsce, jest uprawniona do uzyskania dostępu do nich. Zgodnie
                            z art. 15 Administrator dostarczy osobie, której dane dotyczą, kopię danych osobowych
                            podlegających&nbsp; przetwarzaniu.</Typography></li>
                        <li><strong>Prawo do sprostowania danych (art. 16 RODO)</strong><Typography variant="body1">Osoba,
                            której dane dotyczą,
                            ma prawo żądania od Administratora niezwłocznego sprostowania dotyczących jej
                            danych&nbsp; osobowych, które są nieprawidłowe.</Typography></li>
                        <li><strong>Prawo do usunięcia danych („prawo do bycia zapomnianym”) (art. 17 RODO)</strong>
                            <Typography variant="body1">Osoba, której dane dotyczą, ma prawo żądania od Administratora
                                niezwłocznego usunięcia
                                dotyczących jej danych osobowych, a Administrator ma obowiązek bez zbędnej zwłoki usunąć
                                dane osobowe, jeżeli zachodzi jedna z następujących okoliczności:</Typography>
                            <ol className="type-a">
                                <li>dane osobowe nie są już niezbędne do celów, w których zostały zebrane lub w inny
                                    sposób przetwarzane;
                                </li>
                                <li>osoba, której dane dotyczą, cofnęła zgodę, na której opiera się przetwarzanie</li>
                                <li>osoba, której dane dotyczą, wnosi sprzeciw na mocy art. 21 ust. 1 wobec
                                    przetwarzania i nie występują nadrzędne prawnie uzasadnione podstawy przetwarzania
                                </li>
                            </ol>
                        </li>
                        <li><strong>Prawo do ograniczenia przetwarzania (art. 18 RODO)</strong><Typography
                            variant="body1">Osoba, której dane
                            dotyczą, ma prawo żądania od Administratora ograniczenia przetwarzania w następujących
                            przypadkach:</Typography>
                            <ol>
                                <li>Kiedy dane są nieprawidłowe - na czas do ich poprawy</li>
                                <li>Osoba, której dane dotyczą, wniosła sprzeciw na mocy art. 21 ust. 1 wobec
                                    przetwarzania - do czasu stwierdzenia, czy prawnie uzasadnione podstawy po stronie
                                    Administratora są nadrzędne wobec podstaw sprzeciwu osoby, której dane dotyczą.
                                </li>
                                <li>Przetwarzanie jest niezgodne z prawem, a osoba, której dane dotyczą, sprzeciwia się
                                    usunięciu danych osobowych, żądając w zamian ograniczenia ich wykorzystywania.
                                </li>
                            </ol>
                        </li>
                        <li><strong>5.&nbsp;Prawo do przenoszenia danych (art. 20 RODO)</strong><Typography
                            variant="body1">Osoba, której dane
                            dotyczą, ma prawo otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się
                            do odczytu maszynowego dane osobowe jej dotyczące, które dostarczyła Administratorowi, oraz
                            ma prawo przesłać te dane osobowe innemu administratorowi bez przeszkód ze strony
                            Administratora, któremu dostarczono te dane osobowe. Osoba, której dane dotyczą, ma prawo
                            żądania, by dane osobowe zostały przesłane przez Administratora bezpośrednio innemu
                            administratorowi, o ile jest to technicznie możliwe. Prawo, o którym w niniejszym punkcie,
                            nie może niekorzystnie wpływać na prawa i wolności innych.</Typography></li>
                        <li><strong>&nbsp;6. Prawo do sprzeciwu (art. 21 RODO)</strong><Typography variant="body1">Jeżeli
                            dane osobowe są
                            przetwarzane na potrzeby marketingu bezpośredniego, osoba, której dane dotyczą, ma prawo w
                            dowolnym momencie wnieść sprzeciw wobec przetwarzania dotyczących jej danych osobowych na
                            potrzeby takiego marketingu, w tym profilowania, w zakresie, w jakim&nbsp; przetwarzanie
                            jest związane z takim marketingiem bezpośrednim.</Typography></li>
                    </ol>
                    <Typography variant="body1">Realizacja powyższych uprawnień użytkowników serwisu może następować
                        odpłatnie w przypadkach, gdy
                        stosowne przepisy prawa to przewidują.</Typography><Typography variant="body1">W przypadku
                        naruszenia powyższych uprawnień albo
                        stwierdzenia przez użytkownika serwisu, że jego dane osobowe są przetwarzane przez
                        Administratora niezgodnie z obowiązującym prawem, użytkownikowi serwisu przysługuje prawo
                        wniesienia skargi do organu nadzorczego.</Typography></li>
                <li>Logi serwera
                    <ol>
                        <li>Zgodnie z przyjętą praktyką większości serwisów internetowych, operator serwisu przechowuje
                            zapytania http kierowane do serwera operatora serwisu (informacje o niektórych zachowaniach
                            użytkowników serwisu podlegają logowaniu w warstwie serwerowej). Przeglądane zasoby
                            identyfikowane są poprzez adresy URL. Dokładny wykaz informacji przechowywany w plikach
                            logów serwera www jest następujący:
                            <ol>
                                <li>publiczny adres IP komputera, z którego nadeszło zapytanie,</li>
                                <li>nazwę stacji klienta – identyfikacja realizowana przez protokół http o ile jest
                                    możliwa,
                                </li>
                                <li>nazwa użytkownika serwisu podawana w procesie autoryzacji (logowania),</li>
                                <li>czas nadejścia zapytania,</li>
                                <li>kod odpowiedzi http,</li>
                                <li>liczbę wysłanych przez serwer bajtów,</li>
                                <li>adres URL strony poprzednio odwiedzanej przez użytkownika serwisu (referer link) – w
                                    przypadku, gdy przejście do serwisu nastąpiło przez odnośnik,
                                </li>
                                <li>informacje o przeglądarce internetowej użytkownika serwisu,</li>
                                <li>informacje o błędach jakie nastąpiły przy realizacji transakcji http.</li>
                            </ol>
                            <Typography variant="body1">Powyższe dane nie są kojarzone z konkretnymi osobami
                                przeglądającymi strony dostępne w
                                ramach serwisu. W celu zapewnienia jak najwyższej jakości serwisu, operator serwisu
                                okazjonalnie analizuje pliki z logami w celu określenia, które strony w ramach serwisu
                                odwiedzane są najczęściej, jakie przeglądarki internetowe są stosowane, czy struktura
                                stron nie zawiera błędów, itp.</Typography></li>
                        <li>Zgromadzone przez operatora logi przechowywane są przez czas nieokreślony jako materiał
                            pomocniczy, służący do prawidłowego administrowania serwisem. Informacje w nich zawarte nie
                            będą ujawniane żadnym innym podmiotom niż operator lub podmioty powiązane z operatorem
                            osobowo, kapitałowo lub umownie. Na podstawie informacji zawartych w tych plikach mogą być
                            generowane statystyki stanowiące pomoc w administrowaniu serwisem. Podsumowania zawierające
                            takie statystyki nie zawierają cech identyfikujących osoby odwiedzające serwis.
                        </li>
                    </ol>
                </li>
            </ol>
        </Box>
    )
}

export default Privacy
