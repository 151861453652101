import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Banner from "./Banner";

const useStyles = makeStyles({
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        position: 'relative',
        maxWidth: '1140px',
        textAlign: 'center',
        padding: '1.2rem'
    },
    applicationCardButton: {
        background: '#4fbbe0',
        color: '#fff',
        '&:hover': {
            background: '#20acdb'
        },
    }
})

function Contact() {
    const classes = useStyles();
    const title = "Kontakt"

    return (
        <Box>
            <Banner title={title}/>
            <Box className={classes.content}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs>
                        <Typography variant="h5" gutterBottom>
                            ABC Sikorski Andrzej Sikorski
                            <br/>ul. Igańska 26/45
                            <br/>04-083 Warszawa
                            <br/>NIP 113-010-36-44
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            Adres do korespondencji:
                            <br/>ABC Sikorski
                            <br/>ul. Gen. W. Sikorskiego 138
                            <br/>05-080 Mościska
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">+48 602 23 25 10
                        </Typography>
                        <Typography variant="h5">
                            <Link href="mailto:a.sikorski@abcsikorski.pl">a.sikorski@abcsikorski.pl</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Contact